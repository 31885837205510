<template>
  <div>
    <b-card>
      <div class="custom-search d-flex justify-content-between">
        <div class="d-flex">
          <div>
            <b-button to="/team/add" variant="primary">Add Team</b-button>
          </div>
          <div v-if="itemsToTakeAction.length > 0" class="ml-1">
            <b-button @click="deleteTeam" variant="danger"
              >Delete Selected</b-button
            >
          </div>
        </div>
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="teamMembers"
        :isLoading="isDataLoading"
        @on-select-all="onSelectAll"
        @on-row-click="onRowClick"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-row" slot-scope="props">
          <span
            v-if="props.column.field === 'thumbnailImg'"
            class="text-nowrap"
          >
            <b-avatar
              size="50px"
              :src="props.row.thumbnailImg ? props.row.thumbnailImg : ''"
              class="mx-1"
            />
          </span>

          <span v-else-if="props.column.field === 'name'">
              <router-link :to="`/team/${props.row._id}`">
              {{ props.row.name }}
              </router-link>
          </span>

          <span v-else-if="props.column.field === 'updatedAt'">
            <b-badge variant="light-success">
              {{ getFormattedData(props.row.updatedAt) }}
            </b-badge>
          </span>

          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap"> Showing 1 to </span>
              <b-form-select
                v-model="pageLength"
                :options="['3', '5', '10', '15']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap"> of {{ props.total }} entries </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BCard,
  BButton,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import { getUserToken } from "@/auth/utils";
import axios from "axios";
import moment from "moment-timezone";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
  },
  data() {
    return {
      teamMembers: [],
      pageLength: 15,
      itemsToTakeAction: [],
      isDataLoading: true,
      dir: false,
      columns: [
        {
          label: "Team Member",
          field: "name",
        },
        {
          label: "Image",
          field: "thumbnailImg",
        },
        {
          label: "Updated At",
          field: "updatedAt",
        },
      ],
      searchTerm: "",
    };
  },
  methods: {
    getTeamMembers() {
      axios
        .get(`${process.env.VUE_APP_SERVER_URL}/org/team/get`, {
          headers: {
            Authorization: `Bearer ${getUserToken()}`,
          },
        })
        .then((response) => {
          this.teamMembers = response.data;
          this.isDataLoading = false;
        })
        .catch((error) => {
          this.isDataLoading = false;
          console.log(error.response);
        });
    },
    getFormattedData(date) {
      return moment(date).tz("Asia/Calcutta").format("DD-MM-YYYY HH:MM:SSa");
    },
    deleteTeam() {
      if (confirm("Are you sure want to delete the Team?")) {
        this.isDataLoading = true;
        axios
          .post(
            `${process.env.VUE_APP_SERVER_URL}/org/team/delete`,
            {
              teamIDs: this.itemsToTakeAction,
            },
            {
              headers: {
                Authorization: `Bearer ${getUserToken()}`,
              },
            }
          )
          .then((response) => {
            for (let i = 0; i < this.itemsToTakeAction.length; i++) {
              const item = this.itemsToTakeAction[i];
              const index = this.teamMembers.findIndex((team) => {
                return team._id == item;
              });
              this.teamMembers.splice(index, 1);
            }
            this.itemsToTakeAction = [];
            this.isDataLoading = false;
          })
          .catch((error) => {
            console.log(error);
            this.isDataLoading = false;
          });
      }
    },
    onSelectAll(params) {
      const selectedItems = params.selectedRows.map((item) => {
        return item._id;
      });
      this.itemsToTakeAction = selectedItems;
    },
    onRowClick(params) {
      const item = params.row._id;
      if (!params.selected) {
        const itemIndex = this.itemsToTakeAction.findIndex((itemObj) => {
          return itemObj == item;
        });
        if (itemIndex > -1) {
          this.itemsToTakeAction.splice(itemIndex, 1);
        }
      } else {
        this.itemsToTakeAction.push(item);
      }
    },
  },
  created() {
    this.getTeamMembers();
  },
};
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
